import { useState, PropsWithChildren, ReactNode } from 'react';
import { Head } from '@inertiajs/react';
import ApplicationLogo from '@/Components/ApplicationLogo';

import NavLink from '@/Components/NavLink';
import ResponsiveNavLink from '@/Components/ResponsiveNavLink';
import { Link, usePage } from '@inertiajs/react';
import { PageProps } from '@/types';

export default function PublicLayout({ children }: PropsWithChildren<{ header?: ReactNode }>) {
  const { user } = usePage<PageProps>().props.auth;
  const [showingNavigationDropdown, setShowingNavigationDropdown] = useState(false);

  return (
    <>
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
        <nav className="bg-white dark:bg-gray-800 border-b border-gray-100 dark:border-gray-700">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="shrink-0 flex items-center">
                  <Link href="/">
                    <ApplicationLogo className="block h-9 w-auto fill-current text-gray-800 dark:text-gray-200" />
                  </Link>
                </div>
                <div className="hidden space-x-8 sm:-my-px sm:ms-10 sm:flex">
                  <NavLink href={route('events')} active={route().current('events')}>
                    Eventos
                  </NavLink>
                  {!user ? (
                    <>
                      <NavLink href={route('login')} active={route().current('login')}>
                        Login
                      </NavLink>
                      <NavLink
                        href={route('register.athlete')}
                        active={route().current('register.athlete')}
                      >
                        Registrar-se
                      </NavLink>
                    </>
                  ) : (
                    <NavLink
                      href={route('subscriptions.list')}
                      active={route().current('subscriptions.list')}
                    >
                      Inscrições
                    </NavLink>
                  )}

                  {/*<NavLink href={route('prices')} active={route().current('prices')}>*/}
                  {/*  Preços*/}
                  {/*</NavLink>*/}
                </div>
              </div>
              <div className="-me-2 flex items-center sm:hidden">
                <button
                  onClick={() => setShowingNavigationDropdown((previousState) => !previousState)}
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 dark:text-gray-500 hover:text-gray-500 dark:hover:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-900 focus:outline-none focus:bg-gray-100 dark:focus:bg-gray-900 focus:text-gray-500 dark:focus:text-gray-400 transition duration-150 ease-in-out"
                >
                  <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path
                      className={!showingNavigationDropdown ? 'inline-flex' : 'hidden'}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                    <path
                      className={showingNavigationDropdown ? 'inline-flex' : 'hidden'}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div className={(showingNavigationDropdown ? 'block' : 'hidden') + ' sm:hidden'}>
            <div className="pt-2 pb-3 space-y-1">
              <ResponsiveNavLink href={route('events')} active={route().current('events')}>
                Eventos
              </ResponsiveNavLink>
              {!user ? (
                <>
                  <ResponsiveNavLink href={route('login')} active={route().current('login')}>
                    Login
                  </ResponsiveNavLink>
                  <ResponsiveNavLink
                    href={route('register.athlete')}
                    active={route().current('register.athlete')}
                  >
                    Registrar-se
                  </ResponsiveNavLink>
                </>
              ) : (
                <ResponsiveNavLink
                  href={route('subscriptions.list')}
                  active={route().current('subscriptions.list')}
                >
                  Inscrições
                </ResponsiveNavLink>
              )}
            </div>
          </div>
        </nav>

        <main className="min-h-svh">{children}</main>
        <footer className="bg-white dark:bg-gray-900 text-gray-500 dark:text-white py-4 z-50">
          <div className="container mx-auto px-6">
            <p className="text-center">
              JLS Software Services CNPJ: 31.202.613/0001-35 - 2024. Todos os direitos reservados.
            </p>
          </div>
        </footer>
      </div>
    </>
  );
}
